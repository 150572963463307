import { Auth } from '@/default/service/Auth'

import ChamadoDados from '@/modules/service-desk/config/Ticket/Chamado/ChamadoDados'
import ChamadoDadosResponsavel from '@/modules/service-desk/config/Ticket/Chamado/ChamadoDadosResponsavel'
import ChamadoDadosCriador from '@/modules/service-desk/config/Ticket/Chamado/ChamadoDadosCriador'
import ChamadoTransferir from '@/modules/service-desk/config/Ticket/Chamado/ChamadoTransferir'
import ChamadoPrevisao from '@/modules/service-desk/config/Ticket/Chamado/ChamadoPrevisao'
import ChamadoOcorrencia from '@/modules/service-desk/config/Ticket/Chamado/ChamadoOcorrencia'
import ChamadoConcluido from '@/modules/service-desk/config/Ticket/Chamado/ChamadoConcluido'
import TicketExternalCode from '@/modules/service-desk/config/Ticket/Chamado/TicketExternalCode'
import TicketTeam from '@/modules/service-desk/config/Ticket/Chamado/TicketTeam'
import TicketImpactChange from '@/modules/service-desk/config/Ticket/Chamado/TicketImpact'
import TicketUrgencyChange from '@/modules/service-desk/config/Ticket/Chamado/TicketUrgency'

import ChamadoStatus from '@/modules/service-desk/config/Ticket/Status/ChamadoStatus'

import ChamadoAnotacao from '@/modules/service-desk/config/Ticket/Interacao/ChamadoAnotacao'

import ChamadoOcorrencias from '@/modules/service-desk/config/Ticket/Ocorrencia/ChamadoOcorrencia'

import ChamadoJustificativa from '@/modules/service-desk/config/Ticket/Justificativa/ChamadoJustificativa'

import TicketImpact from '@/modules/service-desk/config/Ticket/Impact/TicketImpact'
import TicketUrgency from '@/modules/service-desk/config/Ticket/Urgency/TicketUrgency'

import ChamadoSeguidor from '@/modules/service-desk/config/Ticket/Seguidor/ChamadoSeguidor'

// Tickets

import Ticket from '@/modules/service-desk/config/Ticket/Ticket'
import TicketNetwork from '@/modules/service-desk/config/Ticket/TicketNetwork'
import TicketDevice from '@/modules/service-desk/config/Ticket/TicketDevice'
import TicketHost from '@/modules/service-desk/config/Ticket/TicketHost'
import TicketDeviceChild from '@/modules/service-desk/config/Ticket/TicketDeviceChild'
import TicketLocation from '@/modules/service-desk/config/Ticket/TicketLocation'
import TicketLocations from '@/modules/service-desk/config/Ticket/TicketLocations'
import TicketSettings from '@/modules/service-desk/config/Ticket/TicketSettings'

// Order Service
import {
  Order,
  OrderOpened,
  OrderClosed,
  OrderCanceled
} from '@/modules/service-desk/config/Order/Order/Order'
import {
  Order as OrderVTal,
  OrderOpened as OrderOpenedVTal,
  OrderClosed as OrderClosedVTal,
  OrderCanceled as OrderCanceledVTal
} from '@/modules/service-desk/config/Order/Order/OrderVTal'

import VTalTroubleTicketAppointment from '@/modules/service-desk/config/Order/Actions/VTalTroubleTicketAppointment'

// Order Service
import { Diagnostic, DiagnosticOpened, DiagnosticClosed, DiagnosticCanceled } from '@/modules/service-desk/config/TestManagements/Diagnostic/Diagnostic'
import DiagnosticEvent from '@/modules/service-desk/config/TestManagements/Common/Event'
import HGWWifiSetPasswd from '@/modules/service-desk/config/TestManagements/Actions/HGWWifiSetPasswd'
import HGWWifiConfiguration from '@/modules/service-desk/config/TestManagements/Actions/HGWWifiConfiguration'

// Common
import Event from '@/modules/service-desk/config/Order/Common/Event'
import Item from '@/modules/service-desk/config/Order/Common/Item'
import Person from '@/modules/service-desk/config/Order/Common/Person'
import Finances from '@/modules/service-desk/config/Order/Common/Finances'
import OrderFinance from '@/modules/service-desk/config/Order/Common/OrderFinance'

import { OrderLayout } from '@/modules/service-desk/config/Layout/OrderLayout'

// Configurations
import Status from '@/modules/service-desk/config/Order/Config/Status'
import { OrderCategory } from '@/modules/service-desk/config/Order/Config/Category'

import diagnosticStatus from '@/modules/service-desk/config/TestManagements/Config/Status'
import { DiagnosticCategory } from '@/modules/service-desk/config/TestManagements/Config/Category'

// Registers
import Product from '@/modules/service-desk/config/Order/Register/Product'
import Service from '@/modules/service-desk/config/Order/Register/Service'
import Customer from '@/modules/service-desk/config/Order/Register/Customer'
import Collaborator from '@/modules/service-desk/config/Order/Register/Collaborator'

// Forms
import { Form } from '@/modules/forms/config/Form/Form'
import { FormField } from '@/modules/forms/config/Form/FormField'
import { FormFieldOption } from '@/modules/forms/config/Form/FormFieldOption'
import { FormReply } from '@/modules/forms/config/Form/FormReply'

import { Process } from '@/modules/service-desk/config/Process/Process'
import { ProcessStage } from '@/modules/service-desk/config/Process/ProcessStage'
import { ProcessStageResult } from '@/modules/service-desk/config/Process/ProcessStageResult'

import { TicketJustify } from '@/modules/service-desk/config/Ticket/TicketJustify'

const AccountIsMaster = Auth.account().id == 'e6aff7a1-92c1-42a8-a3f8-a0e7d0df79db'

export default {
  'chamado': ChamadoDados,
  'chamados-todos': ChamadoDados,
  'chamados-responsabilidade': ChamadoDadosResponsavel,
  'chamados-criados': ChamadoDadosCriador,
  'chamados-transferir': ChamadoTransferir,
  'chamados-previsao': ChamadoPrevisao,
  'chamados-ocorrencia': ChamadoOcorrencia,
  'chamados-concluido': ChamadoConcluido,
  'ticket-external-code': TicketExternalCode,
  'ticket-team': TicketTeam,
  'ticket-impact-change': TicketImpactChange,
  'ticket-urgency-change': TicketUrgencyChange,
  'status-do-chamado': ChamadoStatus,
  'chamados-anotacao': ChamadoAnotacao,
  'tipos-de-ocorrencia': ChamadoOcorrencias,
  'ticket-impact': TicketImpact,
  'ticket-urgency': TicketUrgency,
  'justificativas-de-chamado': ChamadoJustificativa,
  'chamados-seguidor': ChamadoSeguidor,

  'ticket-list': Ticket.ticket,
  'ticket-opened': Ticket.opened,
  'ticket-closed': Ticket.closed,
  'ticket-canceled': Ticket.canceled,
  'ticket-title': Ticket.title,
  'ticket-network': TicketNetwork,
  'ticket-device': TicketDevice,
  'ticket-device-child': TicketDeviceChild,
  'ticket-host': new TicketHost(),
  'ticket-location': new TicketLocation(),
  'ticket-locations': TicketLocations,
  'ticket-settings': new TicketSettings(),
  'ticket-justify': new TicketJustify(),

  'order': AccountIsMaster ? new OrderVTal() : new Order(),
  'order-open': AccountIsMaster ? new OrderOpenedVTal() : new OrderOpened(),
  'order-close': AccountIsMaster ? new OrderClosedVTal() : new OrderClosed(),
  'order-cancel': AccountIsMaster ? new OrderCanceledVTal() : new OrderCanceled(),
  'order-layout': new OrderLayout(),
  
  'diagnostic': new Diagnostic(),
  'diagnostic-open': new DiagnosticOpened(),
  'diagnostic-close': new DiagnosticClosed(),
  'diagnostic-cancel': new DiagnosticCanceled(),

  'diagnostic-event': DiagnosticEvent,
  'diagnostic-action-hgw-wifi-configuration': new HGWWifiConfiguration(),
  'diagnostic-action-hgw-wifi-set-passwd': new HGWWifiSetPasswd(),

  'config-status-diagnostic': diagnosticStatus,
  'config-category-diagnostic': new DiagnosticCategory(),

  'order-event': Event.order,
  'order-item-product': Item.product.order,
  'order-item-service': Item.service.order,
  'order-person': Person,
  'order-finances': Finances.order,
  'order-finance': OrderFinance,
  'vtal-trouble-ticket-appointment': new VTalTroubleTicketAppointment(),

  'config-status-order': Status.order,
  'config-category-order': new OrderCategory(),

  'service-desk-register-product': Product,
  'service-desk-register-service': Service,
  'service-desk-register-customer': Customer,
  'service-desk-register-collaborator': Collaborator,

  'service-desk-config-form': new Form(),
  'service-desk-config-form-field': new FormField(),
  'service-desk-config-form-reply': new FormReply(),
  'service-desk-config-form-field-option': new FormFieldOption(),

  'process': new Process(),
  'process-stage': new ProcessStage(),
  'process-stage-result': new ProcessStageResult(),
}
