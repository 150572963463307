<template>
  <div class="wi-edit__wi-list-dialog">
    <v-list dense two-line>
      <v-list-tile avatar class="wi-edit__wi-list-dialog-item asdasdasd">
        <v-list-tile-avatar @click="WiListDialogAction()">
          <v-icon large :color="color || 'primary'">{{icon || 'category'}}</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content @click="WiListDialogAction()">
          <v-list-tile-sub-title>
            <span>
              {{ text }}
            </span>
          </v-list-tile-sub-title>
          <v-list-tile-title v-if="value">
            <strong>
              ID: {{ value }}
            </strong>
          </v-list-tile-title>
          <v-list-tile-title v-else>
            Clique para buscar
          </v-list-tile-title>
          <v-input
            style="display: none;"
            v-model="filters[field]"
          ></v-input>
        </v-list-tile-content>
        <v-list-tile-action
          @click="WiListDialogAction()"
          style="padding-top: 0 !important; padding-bottom: 0 !important;"
        >
          <v-btn icon small>
            <v-icon>{{ value ? 'edit' : 'search' }}</v-icon>
          </v-btn>
        </v-list-tile-action>
        <v-list-tile-action
          v-if="value"
          
          style="padding-top: 0 !important; padding-bottom: 0 !important;"
        >
          <v-btn v-if="value" icon small @click="clearField()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </div>
</template>
<script>
  export default {
    name: 'FilterWiListDialog',
    data: () => ({
      selected: {}
    }),
    methods: {
      getFilter: function () {
        if (this.field?.filter) {
          if (typeof this.field.filter === 'function') {
            const filter = this.field.filter(this.form)
            return filter
          }
          return this.field.filter
        }
        return false
      },
      getWiEditData: function () {
        if (this.field?.wiEditData) {
          if (typeof this.field.wiEditData === 'function') {
            const wiEditData = this.field.wiEditData(this.form)
            return wiEditData
          }
          return this.field.wiEditData
        }
        return false
      },
      wiListDialogCallback: function (selected) {
        if (selected) {
          this.selected = selected
          this.$emit('select', selected)
          this.$emit('input', selected[this.field])
          this.$forceUpdate()
          if (this.field.onChange) {
            this.field.onChange({form: this.form, data: selected})
          }
        }
      },
      wiListDialogCalbackFields: function () {
        let fields = [
          {get: 'id', set: this.field},
          {get: this.show, set: this.show}
        ]
        return fields
      },
      wiListDialogCalbackShowField: function () {
        if (this.show && this.show.split('.').length > 1) {
          var show = this.show.split('.')
          return this.selected && this.selected[show[0]] ? this.selected[show[0]][show[1]] : 'Clique para selecionar'
        } else {
          return this.selected[this.show]
        }
      },
      WiListDialogAction: function () {
        this.$WiListDialog({
          wiConfig: this.wiConfig,
          callback: this.wiListDialogCallback,
          callbackFields: this.wiListDialogCalbackFields(),
          filter: this.getFilter() || {}, 
          mode: 'select',
          wiEditData: this.getWiEditData() || {}
        })
      },
      clearField: function () {
        const fieldClear = {}
        fieldClear[this.field.value] = null
        this.wiListDialogCallback(fieldClear)
      }
    },
    props: {
      value: {
        required: true
      },
      filters: {
        required: true
      },
      field: {
        required: true
      },
      show: {
        required: true
      },
      wiConfig: {
        required: true
      },
      prependIcon: {
        default: null
      },
      appendIcon: {
        default: null
      },
      icon: {
        default: 'icon'
      },
      color: {
        default: 'color'
      },
      placeholder: {
        default: 'Insira aqui...'
      },
      text: {
        required: true
      },
      multiple: {
        default: false
      },
      returnObject: {
        default: false
      },
    }
  }
</script>

<style scoped>
  .asdasdasd >>> div {
    height: 45px !important;
  }
  .wi-edit__wi-list-dialog {
    max-width: 100%;
    padding-bottom: 12px;
  }
  .wi-edit__wi-list-dialog-item {
    border-style: solid !important;
    border-color: gray !important;
    border-width: 1px !important;
    height: 45px !important;
    cursor: pointer;
  }
</style>
