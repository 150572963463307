import { DateTime } from '@/default/plugins/Utils/DateTime'

const today = DateTime.date()

export default [
  {id: 'd8d95df2', icon: 'dashboard', title: 'Dashboard Financeiro', route: { name: 'dashboard-finance' }},
  {id: 'c80ca89f', icon: 'account_balance', title: 'Saldo/Contas Financeiras', route: { name: 'finance-list', params: { page: 'finance-accounts' } }},
  {
    id: 'b8330e5f',
    icon: 'show_chart',
    title: 'Movimentação',
    children: [
      {id: 'b8330e5f', icon: 'receipt', title: 'Extrato/Movimentação', route: { name: 'finance-list', params: { page: 'finances' } }},
      {id: 'b8330e5f', icon: 'import_export', title: 'Transferências Entre Contas', route: { name: 'disponivel-em-breve' }}
    ]
  },
  {
    id: 'dda4fe70',
    icon: 'get_app',
    title: 'Receitas',
    children: [
      {id: 'dda4fe70', icon: 'vertical_align_bottom', title: 'Contas à Receber', route: { name: 'finance-list', params: { page: 'finance-in-unpaid' } }},
      {id: 'dda4fe70', icon: 'get_app', title: 'Todas as Receitas', route: { name: 'finance-list', params: { page: 'finance-in' } }},
      {id: '40ec1d35', icon: 'downloading', title: 'Receitas Recorrentes (BETA)', route: { name: 'finance-list', params: { page: 'finance-in-recurrence' } }}
    ]
  },
  {
    id: 'c840b593',
    icon: 'publish',
    title: 'Despesas',
    children: [
      {id: 'c840b593', icon: 'vertical_align_top', title: 'Contas à Pagar', route: { name: 'finance-list', params: { page: 'finance-out-unpaid' } }},
      {id: 'c840b593', icon: 'publish', title: 'Todas as Despesas', route: { name: 'finance-list', params: { page: 'finance-out' } }},
      {id: 'e9c12734', icon: 'backup', title: 'Despesas Recorrentes (BETA)', route: { name: 'finance-list', params: { page: 'finance-out-recurrence' } }}
    ]
  },
  {
    id: '59d85f29',
    icon: 'bar_chart',
    title: 'Relatórios',
    children: [
      {id: '4da2381f', icon: 'category', title: 'Receitas por Categorias', route: { name: 'finances-in-by-categories', query: { date_field: 'date_competence', date_start: today, date_end: today } }},
      {id: '3b41a419', icon: 'category', title: 'Despesas por Categorias', route: { name: 'finances-out-by-categories', query: { date_field: 'date_competence', date_start: today, date_end: today } }},
      {id: 'd447931a', icon: 'people_outline', title: 'Receitas por Cliente', route: { name: 'finance-in-by-customer', query: { date_field: 'date_competence', date_start: today, date_end: today } }},
      {id: '47434df2', icon: 'assignment_ind', title: 'Despesas por Colaborador', route: { name: 'finance-out-by-collaborator', query: { date_field: 'date_competence', date_start: today, date_end: today } }},
      {id: '1bdc29b2', icon: 'people_outline', title: 'Despesas por Cliente', route: { name: 'finance-out-by-customer', query: { date_field: 'date_competence', date_start: today, date_end: today } }},
      {id: '7287bfda', icon: 'assignment_ind', title: 'Despesas por Fornecedor', route: { name: 'finance-out-by-provider', query: { date_field: 'date_competence', date_start: today, date_end: today } }}
    ]
  },
  {
    id: '1b113a91',
    icon: 'description',
    title: 'Cadastro',
    children: [
      {id: '1b113a91', icon: 'text_rotation_down', title: 'Categorias de Receitas', route: { name: 'finance-list', params: { page: 'finance-in-categories' } }},
      {id: '1b113a91', icon: 'text_rotate_up', title: 'Categorias de Despesas', route: { name: 'finance-list', params: { page: 'finance-out-categories' } }},
      {id: '1b113a91', icon: 'people_outline', title: 'Clientes', route: { name: 'finance-list', params: { page: 'customer' } }},
      {id: '1b113a91', icon: 'local_atm', title: 'Fornecedores', route: { name: 'finance-list', params: { page: 'provider' } }},
      {id: '1b113a91', icon: 'business', title: 'Empresas (Matriz e Filiais)', route: { name: 'finance-list', params: { page: 'company' } }},
      {id: '1b113a91', icon: 'low_priority', title: 'Centro de Custo', route: { name: 'disponivel-em-breve' }}
    ]
  }
]
