import {Model} from '@/default/model/Model'

export class Business extends Model {
  constructor () {
    super()
    this.params = {
      limit: 10
    }
    this.icon = 'shopping_cart'
    this.search = {
      placeholder: 'Pesquise ID, Razão Social, Status ou Nota Fiscal',
      fields: ['id', 'person.name', 'person.first_name', 'status.name', 'invoice', 'external_code']
    }
    this.components.list.tableRow = () => import('@/modules/shared/pages/Businesses/Businesses')
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe os dados básicos deste negócio.'
      }),
      this.fieldSelectApi({
        value: 'company_id',
        text: 'Empresa (Matriz ou Filial)',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        disabled: (form) => { return form.id != null },
        api: {
          url: 'register/person/company',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name'
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: '',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        wiConfig: '',
        show: 'person.first_name',
        icon: 'person_outline'
      }),
      this.fieldText({
        value: 'external_code',
        text: 'Código Externo',
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      this.fieldSelectApi({
        value: 'business_status_id',
        text: 'Status/Etapa*',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        api: {
          url: '',
          params: form => {
            let params = { fields: 'id,name', where: 'active,1', order: 'order,asc' }
            if (form.is_template) {
              params.where = 'active,1;stage,!=,2'
            }
            return params
          }
        },
        show: 'name',
        disabled: (form) => { return form.paid && form.stage === 2 }
      }),
      this.fieldSelectApi({
        value: 'business_category_id',
        text: 'Categoria do Negócio',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        api: {
          url: '',
          params: {fields: 'id,name', order: 'name,asc'}
        },
        show: 'name'
      }),
      this.fieldText({
        value: 'invoice',
        text: 'N° Nota Fiscal',
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      this.fieldDate({
        value: 'date_start',
        text: 'Data de Emissão',
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      this.fieldDate({
        value: 'date_end',
        text: 'Data de Entrega',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      this.fieldMoney({
        value: 'amount_freight',
        text: 'Valor Frete/Deslocamento',
        size: ['xs12', 'sm4', 'md2', 'lg2']
      }),
      this.fieldMoney({
        value: 'amount_discount',
        text: 'Valor de Desconto',
        size: ['xs12', 'sm4', 'md2', 'lg2']
      }),
      this.fieldSelectApi({
        value: 'collaborator_id',
        text: 'Colaborador Responsável',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        api: {
          url: 'register/person/collaborator',
          params: {fields: 'id,name', order: 'name,asc'}
        },
        show: 'name'
      }),
      this.fieldSwitch({
        value: 'is_template',
        text: 'É uma template',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: (form) => { return !form.id },
      }),
      this.fieldSwitch({
        value: 'make_stock',
        text: 'Integrar com Estoque',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        disabled: (form) => { return form.id && form.stage != 1 },
        showField: () => {
          return this.permissionIsAvaiable('b0ce8f81-78ff-4d79-a9f3-d7415343d2e5')
        }
      }),
      this.fieldSubHeader({
        text: 'Descreva abaixo os detalhes.'
      }),
      this.fieldEditor({
        value: 'description',
        text: 'Descrição da Venda',
        placeholder: 'Ex: Prazos, detalhes importanes, etc.'
      }),
      this.fieldSubHeader({
        text: 'Descreva abaixo as formas/condições de pagamento.'
      }),
      this.fieldEditor({
        value: 'information',
        text: 'Forma/Condições de Pagamento',
        placeholder: 'Ex: Parcelado em 3x.'
      }),
      this.fieldSubHeader({
        text: 'O campo abaixo pode ser usado para registrar observações.'
      }),
      this.fieldTextArea({
        value: 'observation',
        text: 'Observações',
        placeholder: 'Use este campo para adicionar observações.'
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'person.type',
        text: 'PF/PJ'
      }),
      this.fieldList({
        value: 'person.name',
        text: 'Nome do Cliente'
      }),
      this.fieldList({
        value: 'business_status_id',
        text: 'Status/Etapa',
        align: 'center'
      }),
      this.fieldList({
        value: '',
        text: ''
      }),
      this.fieldList({
        value: '',
        text: ''
      }),
      this.fieldList({
        value: 'external_code',
        text: 'Código Externo'
      }),
      this.fieldList({
        value: 'collaborator_id',
        text: 'Colaborador/Categoria'
      }),
      this.fieldList({
        value: 'total_liquid',
        text: 'Total',
        sortable: true
      })
    ])
  }
}
