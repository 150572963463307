import { Model } from '@/default/model/Model'

export class BusinessExport extends Model {
  constructor () {
    super()
    this.list = true
    this.title = 'Exportar dados'

    this.icon = 'upload'
    
    this.maxWidth = '800px'

    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Selecione uma ou mais opções para filtrar.'
    }),
    status_id: this.fieldSelectApi ({
      value: 'status_id',
      text: 'Status',
      show: 'name',
      // multiple: true,
      api: {
        url: '',
        params: {
          fields: 'id,name',
          where: 'active,1',
          order: 'order,asc'
        }
      },
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
    }),
    category_id: this.fieldSelectApi ({
      value: 'category_id',
      text: 'Categoria',
      show: 'name',
      // multiple: true,
      api: {
        url: '',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
    }),
    external_code: this.fieldText ({
      value: 'external_code',
      text: 'Código Externo',
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
    }),
    person_id: this.fieldWiListDialog({
      value: 'person_id',
      text: 'Cliente',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      wiConfig: 'customer',
      show: 'person.first_name',
      icon: 'person_outline'
    }),
    collaborator_id: this.fieldWiListDialog({
      value: 'collaborator_id',
      text: 'Colaborador Responsável',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      wiConfig: 'collaborator',
      show: 'collaborator.first_name',
      icon: 'assignment_ind'
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Filtrar por data'
    }),
    dateField: this.fieldSelect({
      value: 'date_field',
      text: 'Campo de Data',
      placeholder: 'Selecione o campo...',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true,
      options: [
        {
          value: 'date_start',
          text: 'Data de emissão'
        },
        {
          value: 'date_end',
          text: 'Data de entrega'
        },
        {
          value: 'date_validity',
          text: 'Data de validade'
        },
        {
          value: 'opened_at',
          text: 'Data de criação'
        },
        {
          value: 'closed_at',
          text: 'Data de conclusão'
        },
        {
          value: 'canceled_at',
          text: 'Data de cancelamento'
        }
      ],
      defaultOption: 'opened_at'
    }),
    dateStart: this.fieldDate({
      value: 'date_start',
      text: 'Data Inicial',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    dateEnd: this.fieldDate({
      value: 'date_end',
      text: 'Data Final',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    // subHeader4: this.fieldSubHeader({
    //   text: 'Filtrar por Endereço.'
    // }),
    // state_id: this.fieldSelectApi({
    //   value: 'state_id',
    //   text: 'Estado',
    //   show: 'name',
    //   api: {
    //     url: 'register/state',
    //     params: {
    //       order: 'name,asc'
    //     }
    //   },
    //   size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
    // }),
    // city_id: this.fieldSelectApi({
    //   value: 'city_id',
    //   text: 'Cidade',
    //   show: 'name_and_state',
    //   api: {
    //     url: 'register/city',
    //     params: filters => ({
    //       state_id: filters.state_id,
    //       order: 'state_id,asc;name,asc'
    //     })
    //   },
    //   size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
    //   disabled: filters => !filters.state_id
    // }),
    // subHeader3: this.fieldSubHeader({
    //   text: 'Filtrar por Produtos ou Serviços existentes.'
    // }),
    // product_id: this.fieldWiListDialog({
    //   value: 'product_id',
    //   text: 'Produto',
    //   size: ['xs12', 'sm12', 'md6', 'lg6'],
    //   wiConfig: 'product',
    //   show: 'product.name',
    //   icon: 'local_grocery_store'
    // }),
    // service_id: this.fieldWiListDialog({
    //   value: 'service_id',
    //   text: 'Serviço',
    //   size: ['xs12', 'sm12', 'md6', 'lg6'],
    //   wiConfig: 'service',
    //   show: 'service.name',
    //   icon: 'room_service'
    // }),
  }
}
