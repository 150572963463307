import { Model } from '@/default/model/Model'

export class Transfer extends Model {
  constructor() {
    super()
    this.list = true
    this.title = {
      list: null,
      edit: {
        insert: 'Nova Transferência',
        update: 'Editar Transferência'
      }
    }
    this.search = {
      placeholder: 'Pesquise ID, Nome, Status ou Nota Fiscal',
      fields: ['id', 'person.name', 'person.first_name', 'status.name', 'invoice', 'external_code']
    }
    this.params = {
      limit: 10
    }
    this.urlDetails = { name: 'product-transfer-in-collaborator', params: {} }
    this.components.list.tableRow = () => import('@/modules/stock/pages/Transfer/Transfers')
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe os dados básicos desta Transferência.'
      }),
      this.fieldSelectApi({
        value: 'company_id',
        text: 'Empresa (Matriz ou Filial)',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        disabled: (form) => {
          return form.id != null
        },
        api: {
          url: 'register/person/company',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name'
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: '',
        required: true,
        size: ['xs12', 'sm12', 'md7', 'lg7'],
        wiConfig: '',
        show: 'person.first_name',
        icon: 'person_outline',
        disabled: (form) => {
          return form.id != null && form.stage != 1
        }
      }),
      this.fieldSelectApi({
        value: 'business_status_id',
        text: 'Status/Etapa*',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        api: {
          url: '',
          params: form => {
            let params = {
              fields: 'id,name',
              where: 'active,1',
              order: 'order,asc'
            }
            if (form.is_template) {
              params.where = 'active,1;stage,!=,2'
            }
            return params
          }
        },
        show: 'name',
        disabled: (form) => {
          return form.paid && form.stage === 2
        }
      }),
      this.fieldSelectApi({
        value: 'business_category_id',
        text: 'Categoria do Negócio',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        api: {
          url: '',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name',
        disabled: (form) => {
          return form.id != null && form.stage != 1
        }
      }),
      this.fieldText({
        value: 'external_code',
        text: 'Código Externo',
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      this.fieldText({
        value: 'invoice',
        text: 'N° Nota Fiscal',
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      this.fieldDate({
        value: 'date_start',
        text: 'Data de saída',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        disabled: (form) => {
          return form.id != null && form.stage != 1
        }
      }),
      this.fieldDate({
        value: 'date_end',
        text: 'Data de entrada',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        disabled: (form) => {
          return form.id != null && form.stage != 1
        }
      }),
      this.fieldSelectApi({
        value: 'collaborator_id',
        text: 'Colaborador Responsável',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        api: {
          url: 'register/person/collaborator',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name',
        disabled: (form) => {
          return form.id != null && form.stage != 1
        }
      }),
      this.fieldTextArea({
        value: 'observation',
        text: 'Observações da Transferência',
        placeholder: 'Ex: Transferência iniciada em...'
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'person.name',
        text: null
      }),
      this.fieldList({
        value: 'business_status_id',
        text: 'Status/Etapa'
      }),
      this.fieldList({
        value: 'date_start',
        text: 'Data de saída'
      }),
      this.fieldList({
        value: 'date_end',
        text: 'Data de entrada'
      }),
      this.fieldList({
        value: 'collaborator_id',
        text: 'Colaborador/Categoria'
      }),
      this.fieldList({
        value: 'options',
        text: 'Opções',
        sortable: false
      })
    ])
  }
}