import FinanceFields from '@/modules/finance/config/Finance/Finance/FinanceFields'
import { FinanceInFilters } from '@/modules/finance/config/Finance/Finance/FinanceFilters'

export default {
  id: {
    id: 'dda4fe70',
    create: '8313b3c0',
    update: 'dc1919bd',
    delete: 'b7389212'
  },
  list: true,
  title: {
    list: 'Contas a Receber',
    edit: {
      insert: 'Nova conta a receber',
      update: 'Atualizar conta a receber'
    }
  },
  icon: 'get_app',
  apiUrl: 'finance/in',
  apiParams: {
    order: 'date_receive,desc'
  },
  search: {
    placeholder: 'Pesquise ID, descrição, nota fiscal ou pessoa envolvida',
    fields: ['id', 'description', 'invoice', 'person.name']
  },
  fields: FinanceFields,
  fieldsFixed: [
    {value: 'in_out', data: 1},
    {value: 'hide_in_out', data: true}
  ],
  notLoadOnMounted: false,
  showFilterDateToolbar: true,
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  components: {
    list: {
      header: () => import('@/modules/finance/pages/Finances/FinancesHeader'),
      tableRow: () => import('@/modules/finance/pages/Finances/Finances')
    }
  },
  filters: new FinanceInFilters()
}
