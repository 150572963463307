<template>
  <div>
    <slot name="toolbar" :content="detail" :refresh="loadDetail" :loading="loading"></slot>
    <v-toolbar dense :color="!dark ? 'primary' : 'dark'" v-if="!notShowToolbar" style="color: white;">
      <v-btn v-if="!notReturnIcon" icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span v-if="title && !fieldTitle">
          {{title}}
        </span>
        <span v-if="!title && fieldTitle && detail">
          {{detail[fieldTitle]}}
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="toolbarItensBefore" :content="detail" :refresh="loadDetail" :loading="loading"></slot>
      <v-btn v-prms="{'id': wiConfig.id && wiConfig.id.update ? wiConfig.id.update : wiConfig.id}" class="hidden-xs-only hidden-sm-only" v-if="store" :disabled="loading" icon @click="$WiEditDialog({wiConfig: store, onSubmit: loadDetail, data: detail})"><v-icon style="color: white;">edit</v-icon></v-btn>
      <v-btn :disabled="loading" icon @click="loadDetail()"><v-icon style="color: white;">refresh</v-icon></v-btn>
      <slot name="toolbarItensAfter" :content="detail" :refresh="loadDetail" :loading="loading"></slot>
    </v-toolbar>
    <div v-if="loading && notLoad">
      <v-progress-linear :indeterminate="true" style="margin: 0;"></v-progress-linear>
    </div>
    <loading :loading="loading && !notLoad">
      <div v-if="detail">
        <slot :content="detail" :refresh="loadDetail" :loading="loading"></slot>
      </div>
      <div v-if="error" style="width: 100%; text-align: center; padding-top: 50px;">
        <h1 style="padding-bottom: 20px;">Erro ao buscar informações. <br>Tente novamente ou contacte o suporte. </h1>
        <v-btn @click="loadDetail()" color="primary"><v-icon>refresh</v-icon> <span style="padding-left: 5px;">Tentar novamente</span></v-btn>
        <v-btn @click="$router.go(-1)"><v-icon>navigate_before</v-icon> Voltar</v-btn>
      </div>
    </loading>
    <div v-if="editModal">
      <editmodal :store="modalStore" :setform="modalForm" :action="editModal" v-on:action="closeModal" v-on:refresh="loadDetail"></editmodal>
    </div>
  </div>
</template>
<script>
  import { callApi } from '@/default/service/Api'
  import EditModal from '@/default/components/Modal/EditModal'
  import Loading from '@/default/components/Loading'
  export default {
    name: 'ViewDetail_v1-1-2',
    data () {
      return {
        editModal: false,
        modalForm: {},
        modalStore: 'pessoa',
        loading: true,
        detail: null,
        error: false
      }
    },
    watch: {
      '$route': 'loadView'
    },
    computed: {
      url () {
        const url = this.$store.state.url
        return url.base + url.version + url.group
      },
      dark () {
        return this.$wiDark || false
      },
      wiConfig () {
        return this.$store.state.pages[this.store] || {}
      }
    },
    created: function () {
      this.loadDetail()
      if (this.index) {
        this.$store.dispatch('WiView/create', {
          index: this.index,
          data: {
            refresh: this.loadDetail
          }
        })
      }
    },
    beforeDestroy: function () {
      if (this.index) {
        this.$store.dispatch('WiView/delete', this.index)
      }
    },
    methods: {
      loadView: function (router) {
        if (!this.defaultRoute || router.name === this.defaultRoute) {
          this.loadDetail()
        }
      },
      loadDetail: function (config) {
        this.loading = true
        var uri = ''
        var apiParams = (config && config.apiParams) ? config.apiParams : this.apiParams || ''
        var apiUrl = (config && config.apiUrl) ? config.apiUrl : this.apiUrl
        if (this.notGetID) {
          uri = apiUrl
        } else {
          if (this.$route.params && this.$route.params.id > 0) {
            uri = apiUrl + '/' + this.$route.params.id
          }
        }
        const urlParams = new URLSearchParams(apiParams);
        const params = Object.fromEntries(urlParams);
        callApi.get({
          uri,
          params,
          sucess: (data) => {
            this.error = false
            this.loading = false
            this.detail = data.data
            this.$emit('action', false)
          },
          error: (error) => {
            console.log(error);
            this.error = true
            this.loading = false
            var title = 'Erro ao buscar o item de ID: ' + this.$route.params.id + '.'
            if (this.notGetID) {
              title = 'Erro ao buscar informações.'
            }
            this.$WiMakeError({error: error, id: 101, title: title})
          } 
        })
        // .then((data) => {
        //   this.error = false
        //   this.loading = false
        //   this.detail = data.data
        //   this.$emit('action', false)
        // })
        // .catch((error) => {
        //   this.error = true
        //   this.loading = false
        //   var title = 'Erro ao buscar o item de ID: ' + this.$route.params.id + '.'
        //   if (this.notGetID) {
        //     title = 'Erro ao buscar informações.'
        //   }
        //   this.$WiMakeError({error: error, id: 101, title: title})
        // })
      },
      openEditModal: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.editModal = true
      },
      closeModal: function () {
        this.editModal = false
      }
    },
    components: {
      editmodal: EditModal,
      loading: Loading
    },
    props: ['index', 'apiUrl', 'apiParams', 'routeReturn', 'title', 'notGetID', 'notShowToolbar', 'defaultRoute', 'store', 'fieldTitle', 'notLoad', 'notReturnIcon']
  }
</script>