<template>
  <div>
  <v-toolbar dense class="elevation-10" :color="!$wiDark ? 'primary' : 'dark'">
    <v-list class="talk-toolbar__title" two dense flat style="padding-top: 0px;">
      <v-list-tile avatar flat class="talk-toolbar__title-text" @click="TOUGGLE_SHOW_CONTACT_INFO()">
        <v-list-tile-avatar>
          <v-badge overlap right bottom offset-x="22" offset-y="24" color="transparent">
            <template v-slot:badge>
              <img
                v-if="talk.channel.type.code == 'wpp'"
                src="static/icons/monitor/whatsapp.png"
              />
            </template>
            <v-avatar size="40">
              <v-img
                v-if="talk.contact && talk.contact.file"
                :src="talk.contact.file.url"
                lazy-src="static/icons/not-found.png"
              ></v-img>
              <v-img
                v-else-if="talk.contact.type == 1"
                src="static/icons/user_icon_1.png"
              />
              <v-img
                v-else
                src="static/icons/monitor/user-groups-96.png"
              />
            </v-avatar>
          </v-badge>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            <v-icon v-if="talk.contact.type == 2" color="white">groups</v-icon>
            <span style="color: white; font-size:20px;">
              <span v-if="showContactInfo" class="hidden-xs-only hidden-sm-only">
                {{ contactName | stringlength(19) }}
              </span>
              <span v-else class="hidden-xs-only hidden-sm-only">
                {{ contactName | stringlength(30) }}
              </span>
              <span class="hidden-md-only hidden-lg-only hidden-xl-only">
                {{ contactName | stringlength(14) }}
              </span>
            </span>
            <!-- <span style="padding-left: 10px;">
              <v-icon v-if="talk.muted" color="grey lighten-1">
                volume_off
              </v-icon>
            </span> -->
            <v-tooltip v-if="talk.contact.checked == 3" bottom lazy>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on" color="red" small
                >do_not_disturb_on</v-icon>
              </template>
              <span>
                Contato inválido
              </span>
            </v-tooltip>
          </v-list-tile-title>
          <v-list-tile-sub-title style="font-size: 12px; color: white;">
            <span v-if="talk.contact.type == 1">
              <span v-if="talk.contact.number.toString().length == 12">
                <span>
                  {{ talk.contact.number | mask('+## (##) ####-####') }}
                </span>
              </span>
              <span v-else-if="talk.contact.number.toString().length == 13">
                <span>{{ talk.contact.number | mask('+## (##) # ####-####') }}</span>
              </span>
              <span v-else>
                {{ talk.contact.number }}
              </span>
            </span>
            <span class="hidden-xs-only hidden-sm-only" style="color: white;">
              <span style="color: #DCDCDC;"> Canal: </span>
              <span> {{ talk.channel.name }}</span>
            </span>
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-spacer></v-spacer>
    <TalkMenu :talk="talk" color="white" small class="hidden-xs-only hidden-sm-only" :refresh="getTalk"></TalkMenu>
    <v-btn v-if="!showContactInfo" icon small ripple @click="getTalk(talkId)" :loading="loadings.load" style="color: white;">
      <v-icon>refresh</v-icon>
    </v-btn>
    <v-btn v-if="!showContactInfo" icon small ripple @click="closeAction()" style="color: white;">
      <v-icon>close</v-icon>
    </v-btn>
  </v-toolbar>
  <TalkToolbarCustomerProvider :refresh="refresh" />
  <v-divider></v-divider>
  <TalkDrawerRight v-if="drawerRight" />
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import TalkMenu from '@/modules/talk/pages/TalkView/Talk/TalkMenu'
  import TalkDrawerRight from '@/modules/talk/pages/TalkView/Talk/TalkDrawerRight'
  import TalkToolbarCustomerProvider from '@/modules/talk/pages/TalkView/Talk/TalkToolbarCustomerProvider'
  export default {
    name: 'TalkToolbar',
    data: () => ({
      drawer: false,
      tabs: 1
    }),
    computed: {
      ...mapState('Talk', ['talk', 'loadings', 'drawerRight', 'showContactInfo']),
      contactName () {
        if (this.talk?.contact?.person?.name) {
          return this.talk.contact.person.name
        } else if(this.talk?.contact?.name) {
          return this.talk.contact.name
        } else {
          return 'Nome não definido'
        }
      }
    },
    methods: {
      ...mapMutations('Talk', ['TOUGGLE_DRAWER_RIGHT', 'TOUGGLE_SHOW_CONTACT_INFO']),
      ...mapActions('Talk', ['getTalk']),
      editContact: function () {
        this.$WiEditDialog({
          wiConfig: 'talk-contact',
          data: {
            id: this.talk.contact.id,
            talk_channel_id: this.talk.contact.talk_channel_id,
            name: this.talk.contact.name,
            type: this.talk.contact.type,
            person_type: this.talk.contact.person_type,
            person: this.talk.contact.person,
            observation: this.talk.contact.observation
          },
          onSubmit: () => {
            this.refresh()
          },
          redirectOnSubmit: false
        })
      },
      refresh: function () {
        this.getTalk(this.talkId)
      },
      closeAction: function () {
        if (this.mode === 'dialog') {
          this.$emit('onClose')
        } else {
          this.$router.push({name: 'talk-view'})
        }
      }
    },
    props: {
      mode: {
        type: String,
        default: 'page'
      },
      talkId: {
        required: true
      }
    },
    components: {
      TalkMenu,
      TalkDrawerRight,
      TalkToolbarCustomerProvider
    }
  }
</script>
<style scoped>
  .talk-toolbar__title {
    background: transparent !important;
    padding: 0 0 0 10px;
  }
  .talk-toolbar__title-text >>> .v-list__tile {
    padding: 0px !important;
  }
</style>