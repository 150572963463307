// import {
//   PERMISSION_CONTRACT
// } from '@/default/constants/permissions'
import {
  PERMISSION_FORM
} from '@/default/constants/permissions'

export default [
  {
    id: '8123ce38-df17-4302-b84f-fe90f7171fe0',
    icon: 'dashboard',
    title: 'Dashboard de Vendas',
    route: {
      name: 'dashboard-sales'
    }
  },
  // {
  //   id: PERMISSION_CONTRACT,
  //   icon: 'border_color',
  //   title: 'Gestão de Contratos',
  //   children: [
  //     // {
  //     //   id: PERMISSION_CONTRACT,
  //     //   icon: 'view_week',
  //     //   title: 'Processo de Contratos',
  //     //   route: {
  //     //     name: 'process-of-sales',
  //     //     params: {
  //     //       business: 'contract'
  //     //     }
  //     //   }
  //     // },
  //     {
  //       id: PERMISSION_CONTRACT,
  //       icon: 'select_all',
  //       title: 'Contratos em Negociação',
  //       route: {
  //         name: 'sales-list',
  //         params: {
  //           page: 'contract-open'
  //         }
  //       }
  //     },
  //     {
  //       id: PERMISSION_CONTRACT,
  //       icon: 'done_outline',
  //       title: 'Contratos Ativos/Vigentes',
  //       route: {
  //         name: 'sales-list',
  //         params: {
  //           page: 'contract-close'
  //         }
  //       }
  //     },
  //     {
  //       id: PERMISSION_CONTRACT,
  //       icon: 'close',
  //       title: 'Contratos Encerrados',
  //       route: {
  //         name: 'sales-list',
  //         params: {
  //           page: 'contract-cancel'
  //         }
  //       }
  //     }
  //   ]
  // },
  {
    id: 'fbded5ac',
    icon: 'local_grocery_store',
    title: 'Gestão de Vendas',
    children: [{
        id: 'fbded5ac',
        icon: 'view_week',
        title: 'Processo de Vendas',
        route: {
          name: 'process-of-sales',
          params: {
            business: 'sale'
          }
        }
      },
      {
        id: 'fbded5ac',
        icon: 'select_all',
        title: 'Vendas Em Aberto',
        route: {
          name: 'sales-list',
          params: {
            page: 'sale-open'
          }
        }
      },
      {
        id: 'fbded5ac',
        icon: 'done_outline',
        title: 'Histórico de Vendas',
        route: {
          name: 'sales-list',
          params: {
            page: 'sale-close'
          }
        }
      },
      {
        id: 'fbded5ac',
        icon: 'close',
        title: 'Vendas Canceladas',
        route: {
          name: 'sales-list',
          params: {
            page: 'sale-cancel'
          }
        }
      }
    ]
  },
  {
    id: '10b505c9',
    icon: 'shop',
    title: 'Gestão de Orçamentos',
    children: [{
        id: '10b505c9',
        icon: 'view_week',
        title: 'Processo de Orçamento',
        route: {
          name: 'process-of-sales',
          params: {
            business: 'budget'
          }
        }
      },
      {
        id: '10b505c9',
        icon: 'select_all',
        title: 'Orçamentos Em Aberto',
        route: {
          name: 'sales-list',
          params: {
            page: 'budget-open'
          }
        }
      },
      {
        id: '10b505c9',
        icon: 'done_outline',
        title: 'Orçamentos Aprovados',
        route: {
          name: 'sales-list',
          params: {
            page: 'budget-close'
          }
        }
      },
      {
        id: '10b505c9',
        icon: 'file_copy',
        title: 'Modelos de Orçamentos',
        route: {
          name: 'sales-list',
          params: {
            page: 'budget-template'
          }
        }
      },
      {
        id: '10b505c9',
        icon: 'close',
        title: 'Orçamentos Rejeitados',
        route: {
          name: 'sales-list',
          params: {
            page: 'budget-cancel'
          }
        }
      }
    ]
  },
  // {
  //   id: 'c4f8c247',
  //   icon: 'fact_check',
  //   title: 'Todos os Agendamentos',
  //   children: [
  //     {id: 'c4f8c247', icon: 'event', title: 'Agendamentos Em Aberto', route: {name: 'sales-list', params: {page: 'task-sales-opened'}}},
  //     {id: 'c4f8c247', icon: 'event_available', title: 'Histórico de Agendamentos', route: {name: 'sales-list', params: {page: 'task-sales-closed'}}},
  //     {id: 'c4f8c247', icon: 'event_busy', title: 'Desistências', route: {name: 'sales-list', params: {page: 'task-sales-canceled'}}}
  //   ]
  // },
  // {id: 'vnds', icon: 'local_activity', title: 'Gestão de Contratos', route: {name: 'disponivel-em-breve'}},
  {
    id: '7bea0895',
    icon: 'bar_chart',
    title: 'Relatórios',
    children: [{
        id: '1b4f745c',
        icon: 'people_outline',
        title: 'Vendas por cliente',
        route: {
          name: 'sales-by-customer',
          query: {
            date_field: 'date_start',
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01'
          }
        }
      },
      {
        id: '919bb8fa',
        icon: 'people_outline',
        title: 'Orçamentos por cliente',
        route: {
          name: 'budgets-by-customer',
          query: {
            date_field: 'date_start',
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01'
          }
        }
      },
      {
        id: 'dab5ce52',
        icon: 'assignment_ind',
        title: 'Vendas por Vendedor',
        route: {
          name: 'sales-by-collaborator',
          query: {
            date_field: 'date_start',
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01'
          }
        }
      },
      {
        id: 'bffe8c55',
        icon: 'assignment_ind',
        title: 'Orçamentos por Vendedor',
        route: {
          name: 'budgets-by-collaborator',
          query: {
            date_field: 'date_start',
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01'
          }
        }
      }
    ]
  },
  {
    id: 'a02e979e',
    icon: 'description',
    title: 'Cadastro',
    children: [{
        id: 'a02e979e',
        icon: 'local_grocery_store',
        title: 'Cadastro de Produtos',
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-product'
          }
        }
      },
      {
        id: 'a02e979e',
        icon: 'room_service',
        title: 'Cadastro de Serviços',
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-service'
          }
        }
      },
      {
        id: 'a02e979e',
        icon: 'assignment_ind',
        title: 'Colaboradores',
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-collaborator'
          }
        },
        selected: false,
        permission: ['admin']
      },
      {
        id: 'a02e979e',
        icon: 'people_outline',
        title: 'Clientes',
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-customer'
          }
        },
        selected: false
      },
      {
        id: 'a02e979e',
        icon: 'card_giftcard',
        title: 'Cupons de Desconto',
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-coupon'
          }
        },
        selected: false
      }
    ]
  },
  {
    id: 'b9a915a3',
    icon: 'settings',
    title: 'Configurações',
    children: [
      {
        id: 'b9a915a3',
        icon: 'code',
        title: 'Status/Etapas de Contratos',
        route: {
          name: 'sales-list',
          params: {
            page: 'config-status-contract'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'category',
        title: 'Categorias de Contrato',
        route: {
          name: 'sales-list',
          params: {
            page: 'config-category-contract'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'code',
        title: 'Status/Etapas de Venda',
        route: {
          name: 'sales-list',
          params: {
            page: 'config-status-sale'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'category',
        title: 'Categorias de Vendas',
        route: {
          name: 'sales-list',
          params: {
            page: 'config-category-sale'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'code',
        title: 'Status/Etapas do Orçamento',
        route: {
          name: 'sales-list',
          params: {
            page: 'config-status-budget'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'category',
        title: 'Categorias do Orçamento',
        route: {
          name: 'sales-list',
          params: {
            page: 'config-category-budget'
          }
        }
      },
      {
        id: PERMISSION_FORM,
        icon: 'list_alt',
        title: 'Formulários/Checklists',
        route: {
          name: 'sales-list',
          params: {
            page: 'service-desk-config-form'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'category',
        title: 'Categorias de item de Venda/Orçamento',
        route: {
          name: 'sales-list',
          params: {
            page: 'business-item-category'
          }
        }
      }
    ]
  }
]