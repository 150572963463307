import { DeviceFilters } from '@/modules/network/config/Device/DeviceFilters'

const rules = {
  required: () => (value => !!value || 'Campo obrigatório!'),
  maxLength: (maxLength) => (value => (!!value && value.length <= maxLength) || `Máximo de ${maxLength} caracteres.`),
  minLength: (minLength) => (value => (!value || value == '' || value.length >= minLength) || `Mínimo de ${minLength} caracteres.`),
  IPv4: () => (value => (/^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)) || 'Insira um IPv4 válido'),
  MACAddress: () => (value => (/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$/.test(value)) || 'Insira um MAC válido'),
}

export default {
  id: {
    id: 'cb59faf8',
    create: 'a2ee509c',
    update: '742b2ad5',
    delete: 'eab778c7'
  },
  list: true,
  title: {
    list: 'Dispositivos de Rede',
    edit: {
      insert: 'Novo dispositivo',
      update: 'Editar dispositivo'
    }
  },
  filters: new DeviceFilters(),
  icon: 'dns',
  search: {
    placeholder: 'Pesquise ID, nome, IP, Rede ou MAC do Dispositivo',
    fields: ['id', 'nome_disp', 'ip_disp', 'mac_disp', 'external_code', 'network.nome_grupo']
  },
  apiUrl: 'network/device',
  params: {
    limit: 10
  },
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  fieldsSelected: {
    itemName: 'dispositivo(s)'
  },
  components: {
    list: {
      selectedAction: () => import('@/modules/network/pages/Devices/DevicesSelectedAction'),
      tableRow: () => import('@/modules/network/pages/Devices/Devices'),
      tableExpand: () => import('@/modules/network/pages/Devices/DevicesExpand')
    }
  },
  fields: [
    {
      value: 'id',
      text: 'ID',
      list: true,
      search: true,
      align: 'center'
    },
    {
      text: 'Dados básicos deste dispositivo. Nome, IP, se será monitorado e de onde será monitorado.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'nome_disp',
      text: 'Nome',
      placeholder: 'Insira a identificação deste dispositivo',
      type: 'text',
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
      required: true,
      list: true,
      edit: true,
      search: true,
      align: 'center'
    },
    {
      value: 'status_disp',
      text: 'Status',
      list: true,
      align: 'center'
    },
    {
      value: 'sigla_disp',
      text: 'Sigla',
      placeholder: 'Insira a sigla deste dispositivo',
      type: 'text',
      size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
      edit: true
    },
    {
      value: 'ip_disp',
      counter: 15,
      text: 'Endereço IP',
      placeholder: '###.###.###.###',
      type: 'text',
      size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
      required: true,
      list: true,
      edit: true,
      search: true,
      rules: [rules.required(), rules.IPv4(), rules.minLength(7), rules.maxLength(15)],
      align: 'center'
    },
    {
      value: 'mac_disp',
      counter: 17,
      text: 'MAC Address',
      placeholder: '##:##:##:##:##:##',
      mask: 'NN:NN:NN:NN:NN:NN',
      type: 'text',
      size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
      edit: true
    },
    {
      value: 'ativo_disp',
      text: 'Será monitorado?',
      placeholder: 'Sim ou não',
      type: 'select',
      size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
      edit: true,
      options: [ { value: 'active', text: 'Sim' }, { value: 'inactive', text: 'Não' } ],
      default: 'inactive'
    },
    {
      text: 'Código externo, Servidor de Monitoramento, Rede principal, Modelo e Tipo',
      type: 'subheader',
      edit: true
    },
    {
      value: 'external_code',
      counter: 17,
      text: 'Código externo',
      type: 'text',
      size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
      required: false,
      edit: true
    },
    {
      value: 'servidor_dados_id',
      text: 'Servidor de Monitoramento',
      placeholder: 'De onde será monitorado',
      required: true,
      type: 'db-autocomplete',
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ],
      edit: true,
      apiUrl: 'servidor/dados',
      apiParams: {
        fields: 'id,nome_servidor',
        order: 'nome_servidor,asc'
      },
      show: 'nome_servidor'
    },
    {
      value: 'grupo_dados_id',
      text: 'Rede Principal',
      required: true,
      type: 'db-autocomplete',
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ],
      list: true,
      edit: true,
      apiUrl: 'grupo/dados',
      apiParams: {
        where: 'funcao_tipo,2',
        order: 'nome_grupo,asc'
      },
      show: 'nome_sigla_grupo',
      align: 'center'
    },
    {
      value: 'cities',
      text: 'Cidades Atendidas',
      type: 'db-autocomplete',
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
      edit: true,
      multiple: true,
      disabled: true,
      apiUrl: 'register/city',
      show: 'name_and_state'
    },
    {
      value: 'type_id',
      text: 'Tipo de Dispositivo',
      type: 'db-autocomplete',
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
      edit: true,
      apiUrl: 'networks/device/type',
      apiParams: {
        order: 'nome_equipamento_fabricante,asc'
      },
      show: 'name'
    },
    {
      value: 'producer_id',
      text: 'Fabricante',
      type: 'db-autocomplete',
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
      edit: true,
      apiUrl: 'networks/producer',
      apiParams: {
        order: 'nome_tipo,asc'
      },
      show: 'name'
    },
    {
      value: 'ssh_profile_id',
      text: 'Perfil SSH',
      type: 'db-autocomplete',
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
      edit: true,
      apiUrl: 'networks/device/profile',
      apiParams: {
        order: 'description,asc'
      },
      show: 'description'
    },
    {
      text: 'Os campos Modelo/Equipamento e Tipo de Dispositivo serão descontinuados em breve.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'equipamento_dados_id',
      text: 'Modelo/Equipamento (depreciated)',
      type: 'db-autocomplete',
      size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
      edit: true,
      apiUrl: 'equipamento/dados',
      apiParams: {
        order: 'nome_equipamento_fabricante,asc'
      },
      show: 'nome_equipamento_fabricante'
    },
    {
      value: 'disp_tipo_id',
      text: 'Tipo de Dispositivo (depreciated)',
      type: 'db-autocomplete',
      size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
      edit: true,
      apiUrl: 'disp/tipo',
      apiParams: {
        order: 'nome_tipo,asc'
      },
      show: 'nome_tipo'
    },
    {
      text: 'O campo de Observações pode ser usado para descrever este dispositivo.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'obs_disp',
      text: 'Observações',
      type: 'textarea',
      edit: true
    },
    {
      value: 'servidor_dados_id',
      text: 'Pingar',
      list: true,
      align: 'center'
    },
    {
      value: 'id',
      text: 'Interfaces',
      list: true,
      sortable: false
    }
  ],
  fieldsFixed: [
    {
      value: 'ultimo_alter_tipo', data: 1
    },
    {
      value: 'ultimo_alter_tipo_nome', data: 'usuario'
    }
  ]
}
