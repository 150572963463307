import { Model } from '@/default/model/Model'
import { TalkScheduleFilters } from './TalkScheduleFilters'

export class TalkSchedule extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: 'aa41348f',
      create: 'bd040a97',
      update: 'acd7de35',
      delete: 'd5429819'
    }
    this.name = 'talk-schedule'
    this.title = {
      list: 'Agendamentos',
      edit: {
        insert: 'Novo agendamento',
        update: 'Editar agendamento'
      }
    }
    this.apiUrl = 'talks/schedule'
    this.icon = 'calendar_month'
    this.params = {
      limit: 10,
      with: 'channel:id,name,status_id;contact:id,name,file_id,checked,type,number;person:id,name'
    }
    this.search = {
      placeholder: 'Pesquise pelo nome do contato',
      fields: ['contact.name']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.maxWidth = '1200px'
    this.filters = new TalkScheduleFilters()
    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkSchedule')
    this.listFields = {
      id: this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      name: this.fieldList({
        value: 'contact.name',
        text: 'Contato'
      }),
      statistics: this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      status: this.fieldList({
        value: 'status_id',
        text: 'Status',
        align: 'center'
      }),
      action: this.fieldList({
        value: 'action',
        text: 'Configuração',
        sortable: false,
        align: 'center'
      })
    }
    this.editFields = {
      header1: this.fieldSubHeader({
        text: 'Configure o agendamento'
      }),
      talk_contact: this.fieldWiListDialog({
        value: 'talk_contact_id',
        text: 'Contato',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        wiConfig: 'talk-contact-all',
        show: 'contact.name',
        icon: 'person_outline',
        disabled: form => !!form.id
      }),
      talk_channel: this.fieldSelectApi({
        value: 'talk_channel_id',
        text: 'Canal de atendimento',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        api: {
          url: 'talk/channel',
          params: {
            fields: 'id,uuid,name,type_id,status_id',
          }
        },
        show: 'name',
        color: 'status_color',
        icon: 'status_icon',
        disabled: form => !!form.id
      }),
      name: this.fieldText({
        value: 'name',
        text: 'Nome do agendamento',
        placeholder: 'Campo opcional',
        required: false,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
      }),
      person_id: this.fieldWiListDialog({
        value: 'person_id',
        text: 'Colaborator',
        required: false,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        wiConfig: 'collaborator',
        show: 'person.name',
        icon: 'person'
      }),
      messsage_type_id: this.fieldSelect({
        value: 'message_type_id',
        text: 'Tipo',
        options: [
          { value: 1, text: 'Texto simples' },
          { value: 2, text: 'Chatbot', disabled: true },
        ],
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        defaultOption: 1,
        disabled: form => !!form.id
      }),
      talk_option: this.fieldSelect({
        value: 'talk_option',
        text: 'Configuração do atendimento',
        options: [
          { value: 1, text: 'Enviar e encerrar atendimento' }
          // { value: 2, text: 'Adicionar atendimento na fila' }
        ],
        required: true,
        defaultOption: 1,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        disabled: form => !!form.id
      }),
      date_time: this.fieldDateTime({
        value: 'date_time',
        text: 'Data de envio',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        showField: (form) => !form.repeat
      }),
      repeat_type_id: this.fieldSelect({
        value: 'repeat_type_id',
        text: 'Tipo de repetição',
        options: [
          { value: 1, text: 'Todo mês' },
          { value: 2, text: 'Todo ano' },
          { value: 3, text: 'Toda semana' }
        ],
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        disabled: form => !!form.id,
        showField: (form) => !!form.repeat
      }),
      repeat: this.fieldSwitch({
        value: 'repeat',
        text: 'Repetir envio?',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        disabled: true
      }),
      header5: this.fieldSubHeader({
        text: 'Defina o dia do mês e horário do envio.',
        size: ['xs12', 'sm12', 'md7', 'lg7'],
        showField: form => form.repeat && form.repeat_type_id === 1
      }),
      header8: this.fieldSubHeader({
        text: 'Defina a data e horário do envio anual.',
        size: ['xs12', 'sm12', 'md8', 'lg8'],
        showField: form => form.repeat && form.repeat_type_id === 2
      }),
      header7: this.fieldSubHeader({
        text: 'Defina o dia da semana e horário do envio.',
        size: ['xs12', 'sm12', 'md7', 'lg7'],
        showField: form => form.repeat && form.repeat_type_id === 3
      }),
      dayMouth: this.fieldSelect({
        value: 'day',
        text: 'Dia do mês',
        options: [
          { value: 1, text: '1º dia' },
          { value: 2, text: '2º dia' },
          { value: 3, text: '3º dia' },
          { value: 4, text: '4º dia' },
          { value: 5, text: '5º dia' },
          { value: 6, text: '6º dia' },
          { value: 7, text: '7º dia' },
          { value: 8, text: '8º dia' },
          { value: 9, text: '9º dia' },
          { value: 10, text: '10º dia' },
          { value: 11, text: '11º dia' },
          { value: 12, text: '12º dia' },
          { value: 13, text: '13º dia' },
          { value: 14, text: '14º dia' },
          { value: 15, text: '15º dia' },
          { value: 16, text: '16º dia' },
          { value: 17, text: '17º dia' },
          { value: 18, text: '18º dia' },
          { value: 19, text: '19º dia' },
          { value: 20, text: '20º dia' },
          { value: 21, text: '21º dia' },
          { value: 22, text: '22º dia' },
          { value: 23, text: '23º dia' },
          { value: 24, text: '24º dia' },
          { value: 25, text: '25º dia' },
          { value: 26, text: '26º dia' },
          { value: 27, text: '27º dia' },
          { value: 28, text: '28º dia' },
          { value: 29, text: '29º dia' },
          { value: 30, text: '30º dia' },
          { value: 31, text: '31º dia' }
        ],
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        showField: form => form.repeat && form.repeat_type_id === 1
      }),
      dayWeek: this.fieldSelect({
        value: 'day',
        text: 'Dia da semana',
        options: [
          { value: 1, text: 'Domingo' },
          { value: 2, text: 'Segunda-feira' },
          { value: 3, text: 'Terça-feira' },
          { value: 4, text: 'Quarta-feira' },
          { value: 5, text: 'Quinta-feira' },
          { value: 6, text: 'Sexta-feira'},
          { value: 7, text: 'Sábado' }
        ],
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        showField: form => form.repeat && form.repeat_type_id === 3
      }),
      time: this.fieldTime({
        value: 'time',
        text: 'Horário',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: form => form.repeat && [1, 3].includes(form.repeat_type_id)
      }),
      date_time_: this.fieldDateTime({
        value: 'date_time',
        text: 'Data e hora de envio',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        showField: (form) => form.repeat && form.repeat_type_id === 2
      }),
      header4: this.fieldSubHeader({
        text: 'Informe a mensagem que será enviada.',
        showField: form => form.message_type_id === 1
      }),
      headerVariables: this.fieldSubHeader({
        text: 'Variáveis: {{name}}, {{phone}}, {{email}}, {{firstName}}, {{fullName}}',
        showField: form => form.message_type_id === 1
      }),
      text: this.fieldTextArea({
        value: 'text',
        text: 'Mensagem',
        placeholder: 'Mensagem de texto simples',
        required: true,
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        showField: form => form.message_type_id === 1
      }),
      // headerChatbot: this.fieldSubHeader({
      //   text: 'Informe o chatbot que será utilizado.',
      //   showField: (model) => {
      //     return model.type_id === 2
      //   }
      // }),
      // chatBotId: this.fieldWiListDialog({
      //   value: 'talk_menu_id',
      //   text: 'Chatbot',
      //   required: false,
      //   size: ['xs12', 'sm12', 'md6', 'lg6'],
      //   wiConfig: 'talk-menu',
      //   show: 'menu.name',
      //   icon: 'smart_toy',
      //   showField: (model) => {
      //     return model.type_id === 2
      //   }
      // })
    }
    this.setFields(this.listFields)
    this.setFields(this.editFields)
  }
}
