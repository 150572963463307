import { Model } from '@/default/model/Model'

class Movement extends Model {
  constructor() {
    super()
    this.components.list.tableRow = () => import('@/modules/stock/pages/Movement/Movement')
  }

  title = {
    list: null,
    edit: {
      insert: 'Nova Movimentação',
      update: 'Editar Movimentação'
    }
  }
  
  search = {
    placeholder: 'Pesquise ID, Nome, Status ou Nota Fiscal',
    fields: ['id', 'person.name', 'person.first_name', 'status.name', 'invoice', 'external_code']
  }

  params = {
    limit: 10
  }
  
  urlDetails = {
    name: null,
    params: {}
  }

  size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']

  // Edit Fields
  editSubHeader = this.fieldSubHeader({
    text: 'Informe os dados básicos desta Movimentação.'
  })
  editCompany = this.fieldSelectApi({
    value: 'company_id',
    text: 'Empresa (Matriz ou Filial)',
    required: true,
    size: ['xs12', 'sm12', 'md5', 'lg5'],
    disabled: (form) => {
      return form.id != null
    },
    api: {
      url: 'register/person/company',
      params: {
        fields: 'id,name',
        order: 'name,asc'
      }
    },
    show: 'name'
  })
  editStatus = this.fieldSelectApi({
    value: 'business_status_id',
    text: 'Status/Etapa*',
    required: true,
    size: ['xs12', 'sm12', 'md6', 'lg6'],
    api: {
      url: '',
      params: form => {
        let params = {
          fields: 'id,name',
          where: 'active,1',
          order: 'order,asc'
        }
        if (form.is_template) {
          params.where = 'active,1;stage,!=,2'
        }
        return params
      }
    },
    show: 'name',
    disabled: (form) => {
      return form.paid && form.stage != 1
    }
  })
  editCategory = this.fieldSelectApi({
    value: 'business_category_id',
    text: 'Categoria do Negócio',
    required: true,
    size: ['xs12', 'sm12', 'md6', 'lg6'],
    api: {
      url: '',
      params: {
        fields: 'id,name',
        order: 'name,asc'
      }
    },
    show: 'name',
    disabled: (form) => {
      return form.id != null && form.stage != 1
    }
  })
  editInvoice = this.fieldText({
    value: 'invoice',
    text: 'N° Nota Fiscal',
    size: ['xs12', 'sm12', 'md2', 'lg2']
  })
  editDate = this.fieldDate({
    value: 'date_start',
    text: 'Data da movimentação',
    size: ['xs12', 'sm12', 'md2', 'lg2'],
    disabled: (form) => {
      return form.id != null && form.stage != 1
    },
    required: true
  })
  editExternalCode = this.fieldText({
    value: 'external_code',
    text: 'Código Externo',
    size: ['xs12', 'sm12', 'md2', 'lg2']
  })
  editCollaborator = this.fieldSelectApi({
    value: 'collaborator_id',
    text: 'Colaborador Responsável',
    required: true,
    size: ['xs12', 'sm12', 'md6', 'lg6'],
    api: {
      url: 'register/person/collaborator',
      params: {
        fields: 'id,name',
        order: 'name,asc'
      }
    },
    show: 'name',
    disabled: (form) => {
      return form.id != null && form.stage != 1
    }
  })
  editObservation = this.fieldTextArea({
    value: 'observation',
    text: 'Observações da Movimentação',
    placeholder: 'Ex: Movimentação iniciada em...'
  })

  // List Fields
  listId = this.fieldList({
    value: 'id',
    text: 'ID'
  })
  listStatus = this.fieldList({
    value: 'business_status_id',
    text: 'Status/Etapa'
  })
  listDate = this.fieldList({
    value: 'date_start',
    text: 'Data da movimentação'
  })
  listCollaborator = this.fieldList({
    value: 'collaborator_id',
    text: 'Colaborador/Categoria'
  })
  listOptions = this.fieldList({
    value: 'options',
    text: 'Opções',
    sortable: false
  })
}

export class MovementAction extends Movement {
  constructor () {
    super()
    this.setFields([
      this.editSubHeader,
      this.editCompany,
      this.fieldStock,
      this.editStatus,
      this.editCategory,
      this.editInvoice,
      this.editDate,
      this.editExternalCode,
      this.editCollaborator,
      this.editObservation
    ])
    this.setFields([
      this.listId,
      this.listStock,
      this.listStatus,
      this.listDate,
      this.listCollaborator,
      this.listOptions
    ])
  }

  fieldStock = this.fieldWiListDialog({
    value: 'stock_id',
    text: 'Estoque',
    required: true,
    size: ['xs12', 'sm12', 'md7', 'lg7'],
    wiConfig: 'stock',
    show: 'stock.name',
    icon: 'my_location',
    disabled: (form) => {
      return form.id != null && form.stage != 1
    }
  })

  listStock = this.fieldList({
    value: 'stock.name',
    text: 'Estoque'
  })

}

export class MovementPerson extends Movement {
  constructor () {
    super()
    this.setFields([
      this.editSubHeader,
      this.editCompany,
      this.editPerson,
      this.editStatus,
      this.editCategory,
      this.editInvoice,
      this.editDate,
      this.editExternalCode,
      this.editCollaborator,
      this.editObservation
    ])
    this.setFields([
      this.listId,
      this.listPerson,
      this.listStatus,
      this.listDate,
      this.listCollaborator,
      this.listOptions
    ])
  }

  editPerson = this.fieldWiListDialog({
    value: 'person_id',
    text: '',
    required: true,
    size: ['xs12', 'sm12', 'md7', 'lg7'],
    wiConfig: '',
    show: 'person.first_name',
    icon: 'person_outline',
    disabled: (form) => {
      return form.id != null && form.stage != 1
    }
  })

  listPerson = this.fieldList({
    value: 'person.name',
    text: null
  })

}