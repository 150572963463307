<template>
  <div>
    <h4 v-if="contact.description" style="word-break: break-all; margin: 20px;">
      <v-divider></v-divider>
      <strong style="word-break: break-all; color: grey;">
        Descrição:
      </strong>
      <br/>
      <span style="word-break: break-all;">
        {{ contact.description }}
      </span>
      <v-divider></v-divider>
    </h4>
  </div>       
</template>
<script>
  export default {
    name: 'TalkContactDescription',
    props: ['contact']
  }
</script>